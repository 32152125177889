// 设计稿 640/1136  1rem=40px  设计稿 960/1704  1rem=60px  设计稿 1080/1917  1rem=67.5px  
// 设计稿要除2 在浏览器显示 

(function (){
  setRem()
  window.addEventListener('orientationchange',setRem);
  window.addEventListener('resize',setRem);

  // function setRem(){
  //   /// 手机端
  //   let html= document.querySelector('html');
  //   let width =html? html.getBoundingClientRect().width:0;
  //   html?html.style.fontSize = width / 16 + 'px':'';
  // }
  function setRem(){
    /// 电脑端
    let html = document.querySelector('html');
    let width =html? html.getBoundingClientRect().width:0;
    if((width/128)<=12){
      html?html.style.fontSize = 12 + 'px':'';
    }else{
      html?html.style.fontSize = width/128+'px':'';
    }
  }
})()
